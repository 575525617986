<template>
  <v-pagination
    outlined
    style="background: #FFF;"
    :color="$theme.primary"
    total-visible="7"
    v-model="get_lojas_filtros.page"
    :length="get_lojas.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_lojas", "get_lojas_filtros"])
  },
  methods: {
    ...mapActions(["listar_lojas"]),
    setPage(page) {
      if (page) {
        console.log("page", page);
        this.get_lojas_filtros.page = page;
        this.listar_lojas();
      }
    }
  }
};
</script>
